.home-mobile{
    position: static;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    margin-top: 5.5rem;
    background-color: var(--main-background-0);
    height: 90vh;
    overflow: auto;
}

.interaction-amount{
    font-size: 1rem;
    padding: 0px;
    margin-right: 1rem;
}

.interaction-icon{
    margin-left: 1rem;
    margin-right: 1rem;
}

* > .interaction-button{
    display: flex;
    padding: 0px;
    justify-content: space-around;
    align-items: center;
}