.header{
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 4.5rem;
    background-color: black;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    box-shadow: 0rem 4px 4px rgba(0, 0, 0, .25);
    z-index: 3;
    position: fixed;
}

.author-name-article{
    font-family: var(--author-family);
    margin-bottom: 3rem;
}

.logo{
    width: 210px;
    background-image: url('../../../../../public/logo512.png');
    background-size: contain;
    background-color: black;
}

.icon{
    background-image: url('../../../../../public/profile-picture.png');
    width: 3em;
    height: 3em;
}

.login-button{
    flex-grow: 0;
    align-self: center;
    height: min-content;
}

.about-widget{
    background-color: rgba(92, 0, 5, .25);
    padding-left: 2rem;
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 1.5rem;
    z-index: 5;
}

.about-widget > * {
    margin: 1rem;
}

.about-title {
    font-family: var(--main-title-family);
    font-weight: var(--title-weight);
    font-size: 2rem;
}

.about-widget > .about-text{
    font-family: var(--main-text-family);
    font-weight: var(--main-text-weight);
    font-style: var(--text-style);
    font-size: 1.25rem;
    color: var(--color-secondary-1-1);
}

/* For responsive right-column layout */

@media screen and (max-width: 1140px) {
    .about-widget{
        display: none;
        opacity: 0;
    }
    .page-right{
        display: none;
    }
    .page-layout-div > .page-center{
        width: 60%;
    }
    .page-layout-div > .page-left{
        width: 40%;
    }
    .header{
        width: 86.5%;
        padding-right: 3.5%;
    }
}

.about-text{
    color: white;
}

.about-title{
    color: var(--color-secondary-2-2);
}

.article{
    background-color: var(--main-background-0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: .2s;
    width: 96%;
    margin-bottom: 2rem;
}

.article.skew-one{
    --skew-axis-x: -1deg;
    --skew-axis-y: 1deg;
    transform: skew(var(--skew-axis-x), var(--skew-axis-y));
}

.article.skew-two{
    --skew-axis-x: 2deg;
    --skew-axis-y: 1deg;
    transform: skew(var(--skew-axis-x), var(--skew-axis-y));
}

.article.skew-three{
    --skew-axis-x: -2deg;
    --skew-axis-y: 1deg;
    transform: skew(var(--skew-axis-x), var(--skew-axis-y));
}

.article.skew-four{
    --skew-axis-x: 1deg;
    --skew-axis-y: 1deg;
    transform: skew(var(--skew-axis-x), var(--skew-axis-y));
}

.article:hover{
    background-color: rgba(162, 210, 226, .4);
    transition: .2s;
}

.article-text{
    font-family: var(--main-text-family);
    font-weight: var(--main-text-weight);
    font-style: var(--text-style);
    color: var(--color-secondary-1-1);
}

.article-body > .headline{
    font-family: var(--main-title-family);
    font-weight: var(--title-weight);
    font-size: 1.75rem;
}

.article-image{
    width: 100%;
    border-radius: .25rem;
}

.info-bar{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94%;
    height: min-content;
    color: var(--color-secondary-2-2);
    font-size: 1.25rem;
    margin-bottom: -1rem;
}

.copied-div{
    position: fixed;
    bottom: 3rem;
    left: 14rem;
    background-color: var(--color-secondary-1-2);
    padding: 2rem;
    border-radius: .75rem;
    border: none;
    opacity: 0;
    box-shadow: -5px 10px 0px rgba(0, 0, 0, .25);
}

@keyframes hide {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

@keyframes show {
    0%{
        opacity: 0;
    }
    50%{
        opacity: 100;
    }
    100%{
        opacity: 0;
    }
}

.info-bar > .logo {
    width: min-content;
    flex: 0 0 auto;
    justify-self: flex-start;
    border-radius: 10rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 1rem;
}

.writer > * > .icon {
    width: 2em;
    height: 2em;
    min-width: 19%;
}

.info-bar > a {
    width: fit-content;
    flex-grow: 0;
    text-decoration: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    margin-left: 1rem;
    margin-right: auto;
}

.article-body{
    width: 85%;
    padding-left: 2rem;
    padding-right: 1rem;
    color: white;
}

.article-body > .date{
    font-size: .7rem;
    margin: 0px;
    margin-top: -1rem;
    margin-bottom: -.6rem;
    margin-left: .1rem;
    color: var(--color-primary-1);
    font-family: var(--author-family);
}

.article-body > h3{
    margin-left: -1rem;
    margin-bottom: 1rem;
}

.interaction-bar{
    display: flex;
    align-items: center;
    width: 90%;
    justify-content: space-between;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
}

.like-div{
    display: none;
    align-items: center;
}

.mail-button, .share-button {
    border: none;
    border-radius: .75rem;
    font-size: 1rem;
    padding: .5rem 1rem;
    background: none;
    color: var(--color-secondary-1-1);
    font-family: var(--main-text-family);
    font-weight: var(--main-text-weight);
    font-style: var(--text-style);
    transition: .3s;
}

.mail-button:hover, .share-button:hover {
    color: var(--color-secondary-2-2);
    background-color: var(--color-primary-3);
    transition: .3s;
    opacity: .75;
}

.citation-links{
    position: relative;
    color: var(--color-secondary-2-2);
    z-index: 0;
}

.citation-menu{
    position: absolute;
    top: auto;
    right: 0;
    background-color: black;
    border-radius: .5rem;
    border-color: var(--color-secondary-2-1);
    border-width: .1rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
    overflow: auto;
    height: 20rem;
}

.hide{
    display: none;
}

.citation-link{
    margin-top: .1rem;
    margin-bottom: .1rem;
    padding: .5rem 1rem;
    background-color: var(--main-background-0);
}

.citation-link > a{
    color: inherit;
    text-decoration: none;
}

.citation-button{
    font-size: 2rem;
}

.citation-button:active{
    text-decoration: none;
    animation: none;
}