.writer-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    margin-top: 11rem;
    background-color: var(--main-background-0);
    min-height: 70vh;
}

.writer-name{
    color: var(--color-secondary-2-2);
    font-size: 3rem;
    font-family: var(--author-family);
    text-align: center;
}

* > div.writer-banner{
    position: fixed;
    top: 5.5rem;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spacing-div{
    height: 5.5rem;
    flex-shrink: 0;
    flex-basis: 0;
}