.bottom-bar{
    display: flex;
    flex-direction: row;
    list-style: none;
    background-color: black;
    position: fixed;
    bottom: 0px;
    margin-bottom: auto;
    max-width: 100%;
    width: 100vw;
    height: 5.5rem;
    justify-content: center;
    align-items: center;
    padding: 0px;
    z-index: 2;
}

.bottom-bar-button{
    color: var(--color-primary-1);
    background-color: var(--main-background-0);
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 2.5rem;
    display: flex;
    align-items: space-around;
    justify-content: space-around;
    margin: auto;
    padding: 0px;
    list-style-position: inside;
    padding-left: 0px;
}

.bottom-bar-button.highlighted{
    color: var(--color-secondary-1-1);
    background-color: var(--color-primary-0);
    animation: Fade-In-Red .5s linear 0s 1 normal;
}

.bottom-bar-icon{
    font-size: 1rem;
    margin: auto;
    width: min-content;
    height: min-content;
}

.nav-link{
    color: unset;
    margin: auto;
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
}

.nav-link:active{
    background-color: unset;
    -webkit-tap-highlight-color: transparent;
}

.top-bar{
    display: flex;
    flex-direction: row;
    list-style: none;
    background-color: black;
    position: fixed;
    top: 0px;
    margin-top: auto;
    max-width: 100%;
    width: 100vw;
    height: 5.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    z-index: 2;
}

.top-bar-icon{
    height: 5.5rem;
}

.left-margin{
    margin-left: 1rem;
}

.right-margin{
    margin-right: 1rem;
}

.auto-left-margin{
    margin-left: auto;
}

.positionary{
    position: relative;
}

.filter-list{
    position: fixed;
    top: 5.5rem;
    right: 0;
    background-color: var(--main-background-0);
    width: 100vw;
    max-height: 85vh;
    text-align: center;
    justify-content: space-around;
    overflow: auto;
}

.layout-div{
    min-height: 6rem;
}

.author-link{
    text-decoration: none;
    background-color: var(--color-secondary-1-2);
    border-radius: .5rem;
    border: none;
    margin-top: 3rem;
    padding: 1rem;
    color: var(--color-secondary-2-2);
    font-size: 2.5rem;
    width: 80vw;
    overflow-wrap: break-word;
}

.author-link.override{
    text-decoration: none;
    background-color: var(--color-secondary-1-2);
    border-radius: .5rem;
    border: none;
    margin-top: 3rem;
    padding: 1rem;
    color: var(--color-secondary-2-2);
    font-size: 2.5rem;
    width: 80vw;
    white-space: nowrap;
    overflow-x: hidden;
}

.icon.override{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    background-color: gray;
    margin-right: .5rem;
}

.icon{
    width: 1rem;
    height: 1rem;
    border-radius: 10rem;
    background-color: gray;
    margin-right: .5rem;
}


.info-row{
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 5%;

}

.fake-image{
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    background-color: grey;
    background-image: url('../../../../../public/profile-picture.png');
    background-size: contain;
}

.info-row > .author-name{
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-right: auto;
    color: var(--color-secondary-2-2);
    position: static;
}

.citation-links{
    position: relative;
    color: var(--color-secondary-2-2);
    z-index: 0;
}

.citation-menu{
    position: absolute;
    top: auto;
    right: 0;
    background-color: black;
    border-radius: .5rem;
    border-color: var(--color-secondary-2-1);
    border-width: .1rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 1;
}

.hide{
    display: none;
}

.hidden{
    opacity: 0;
}

.away{
    top: -50rem;
}

.visible{
    opacity: 100;
    top: 40vh;
}

.citation-link{
    margin-top: .1rem;
    margin-bottom: .1rem;
    padding: .5rem 1rem;
    background-color: var(--main-background-0);
}

.citation-link > a{
    color: inherit;
    text-decoration: none;
}

.citation-button{
    font-size: 2rem;
}

.citation-button:active{
    text-decoration: none;
    animation: none;
}

.interaction-group{
    display: flex;
    justify-self: flex-start;
}

.interaction-button{
    border: none;
    background-color: inherit;
    font-size: inherit;
    color: inherit;
    background-color: var(--color-secondary-1-3);
    padding: .5rem;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;

}

.interaction-amount{
    color: inherit;
    font-size: inherit;
}

.interaction-row{
    font-size: 1.5rem;
    color: var(--color-primary-1);
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.interaction-group.last-button{
    justify-self: flex-end;
}

.spacing-div{
    height: 5.5rem;
}

.article-group{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid var(--color-secondary-1-3) .5rem;
    box-shadow: 0rem .25rem 1rem rgba(2, 45, 59, .8);
}

.article{
    font-family: var(--main-text-family);
}

.headline, .article{
    width: 87%;
}

.headline{
    color: var(--color-primary-1);
    margin-bottom: -.5rem;
    margin-left: -5px;
    font-family: var(--main-title-family);
    font-weight: var(--title-weight);
    font-size: 1.5rem;
}

.article-group > .article{
    color: var(--color-secondary-1-1);
    width: 87%;
}

.article-group > .image{
    width: 87%;
    border-radius: .75rem;
    margin-top: 1rem;
}

.date {
    font-size: .7rem;
    color: var(--color-primary-1);
    margin-right: auto;
    margin-left: 1.75rem;
    margin-bottom: -.5rem;
}

@keyframes Fade-In-Red {
    0% {
        color: var(--color-primary-1);
        background-color: var(--main-background-0);
    }
    50% {
        color: var(--color-secondary-1-1);
        background-color: var(--color-primary-0);
    }
}

:root{
    background-color: var(--main-background-0);
}

.copy-dialogue{
    transition: .5s;
    position: fixed;
    left: auto;
    right: auto;
    bottom: auto;
    background-color: rgba(2, 45, 59, .8);
    padding: 3rem;
    font-size: 3rem;
    border-radius: .5rem;
    pointer-events: none;
}