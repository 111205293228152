* {

    --color-primary-0: #971E24;
    --color-primary-1: #FEB3B7;
    --color-primary-2: #FB8E93;
    --color-primary-3: #5C0005;
    --color-primary-4: #190001;
    
    --color-secondary-1-0: #164E60;	/* Main Secondary color (1) */
    --color-secondary-1-1: #A2D2E2;
    --color-secondary-1-2: #6398AA;
    --color-secondary-1-3: #022D3B;
    --color-secondary-1-4: #000C10;
    
    --color-secondary-2-0: #7A931D;	/* Main Secondary color (2) */
    --color-secondary-2-1: #ECFCB1;
    --color-secondary-2-2: #DFF58B;
    --color-secondary-2-3: #475A00;
    --color-secondary-2-4: #131900;
    
    --main-background-0: #1E1919; /* Main Background Color */    

    --main-text-family: 'Courier Prime', 'DM Serif Display', monospace;
    --main-title-family: 'Abril Fatface', serif;
    --author-family: 'Sen', 'Playfair Display', serif;
    --text-weight: 400;
    --title-weight: 400;
    --author-weight: 600;
    --text-style: italic;
    --author-style: italic;

    
    background-size: contain;
}

html{
    overflow: visible;
    overflow-y: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&family=DM+Serif+Display:ital@0;1&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Sen:wght@400;500;600;700;800&display=swap');